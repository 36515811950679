import {
  AdvancedAnalysisFilterType,
  GeneralReportFilterType,
  PatientNameDisplay,
  ReadStatusType,
  ReportAccessAction,
  ReportChangeAction,
  ReportCharacteristic,
  ReportStatus,
} from 'interfaces/api';

export default {
  listHeading: 'Report List',
  mobileTitle: 'Report Detail',
  sort: {
    reportDate: 'Order Date',
    importDate: 'Report Date',
    help: 'Sort by initial creation in the Laboratory (Order Date) or by the last Import in the Portal (Report Date).',
  },
  preferences: {
    sorting: 'Sorting',
    defaultLaboratoryGroup: 'Chose the default selected Laboratory Group',
    defaultOrder: 'Choose the default sort order',
    pinnedOnTop: 'Show pinned reports on top of list',
    initialOpenCumulative: 'Open cumulative view by default',
    cumulativeSingleReport: 'Show cumulative on single reports',
    listShowExternalTnr: 'Show extnr instead of tnr in reports list',
    deltaCheckFilter: 'Show delta check in reports filter',
    useRealReRequest: 'Use real re-request for OA',
    enableAdvancedAnalysesFilter: 'Enable advanced analyses filter',
    addAnalysis: 'Add analysis',
    barcode: {
      title: 'Barcode Customization',
      prefixLength: 'Barcode Prefix Length',
      barcodeLength: 'Barcode Length',
      suffixLength: 'Barcode Suffix Length',
      enableCustomization: {
        label: 'Enable Barcode Customization',
        description: '',
      },
    },
    lanr: {
      enable: 'Enable LANR Search',
      description: 'Add one or more LANRs and synonyms, the synonym will appear in the Sidebar`s LANR Search Dropdown',
      labels: {
        lanr: 'LANR',
        synonym: 'Synonym',
        default: 'Default',
        add: 'Add LANR entry',
      },
    },
    notifications: {
      enable: 'Enable report notifications',
    },
    patientSettings: {
      title: 'Patient Settings',
      canViewPartialResults: 'Can view partial results by default',
      canViewPreliminaryResults: 'Can view preliminary results by default',
      patientsListShowInsuranceNumber: 'Show insurance number in patient list',
      patientsListShowPatientIdentification: 'Show pident in patient list',
      patientsListShowEmail: 'Show email in patient list',
      patientsListShowBirthday: 'Show birthday in patient list',
      patientNameDisplayEnabled: 'Enable custom patient display format',
      patientNameDisplay: {
        label: 'Custom patient display format',
        options: {
          [PatientNameDisplay.FirstLast]: 'Firstname Lastname',
          [PatientNameDisplay.LastFirst]: 'Lastname Firstname',
          [PatientNameDisplay.FirstCommaLast]: 'Firstname, Lastname',
          [PatientNameDisplay.LastCommaFirst]: 'Lastname, Firstname',
          [PatientNameDisplay.TitleFirstLast]: 'Title Firstname Lastname',
          [PatientNameDisplay.LastFirstTitle]: 'Lastname Firstname Title',
          [PatientNameDisplay.TitleFirstCommaLast]: 'Title Firstname, Lastname',
          [PatientNameDisplay.LastCommaFirstTitle]: 'Lastname, Firstname Title',
          [PatientNameDisplay.LastCommaTitleFirst]: 'Lastname, Title Firstname',
        },
      },
    },
  },
  isLocked: 'Locked report',
  hasSubReports: 'Has Subreports',
  isUnread: 'Unread',
  isPoct: 'POCT report',
  hasAttachments: 'Attachments available',
  hasComments: 'Comments available',
  isExtreme: 'Extreme pathological',
  isPinned: 'Pinned',
  lanrSearch: {
    title: 'Lanr',
    info: 'Set Lanr here',
    all: 'All',
    noneAvailable: 'Lanr not configured',
  },
  quickFilters: {
    pathological: 'Pathological reports',
    unread: 'Unread reports',
    alert: 'Alert reports',
    corrected: 'Corrected reports',
    pinned: 'Pinned reports',
    today: 'Today\'s reports',
  },
  showAllReports: 'Show all reports',
  sidebar: {
    settings: {
      title: 'Settings',
      tabs: {
        general: 'General',
        notifications: 'Notifications',
        lanr: 'LANR',
        analyses: 'My analyses',
      },
    },
  },
  details: {
    orderComments: 'Order Comments',
    generalGroup: 'General',
    otherAnalyses: 'Other analyses',
    label: {
      value: 'Current value',
      follows: 'follows',
      valueRef: 'Normal range',
      previousValue: 'Previous value',
      previousValueDate: 'Previous date',
      openReport: 'Open report {tnr}',
    },
    mibi: {
      header: 'Microbiological Report',
    },
    validatingDoctor: 'Validating Doctor',
    laboratoryNumber: 'Laboratory Number',
    orderNumber: 'Order Number',
    registeredAt: 'Registration date',
    validatedAt: 'Validation date',
    sampledAt: 'Sample date',
    material: 'Material',
    accountingType: 'Accounting type',
    pathoFilter: {
      placeholder: 'Filter pathological',
      pathoOnly: 'Pathological only',
      nonPathoOnly: 'None pathological only',
    },
    prevValueFilter: {
      placeholder: 'Filter previous values',
      prevOnly: 'With previous values',
      nonPrevOnly: 'Without previous values',
    },
    poctFilter: {
      placeholder: 'Filter POCT',
      poctOnly: 'POCT only',
      nonPoctOnly: 'Non POCT only',
    },
  },
  pinnedHeader: 'Pinned reports',
  orderComment: 'Order Comment',
  filters: {
    status: 'Report Status',
    laboratoryGroup: 'Laboratory Group',
    locked: {
      label: 'Locked',
      values: {
        onlyLocked: 'Only locked',
        onlyUnlocked: 'Only unlocked',
      },
    },
    gender: 'Gender',
    characteristics: 'Shaping',
    type: 'Report Type',
    advancedAnalysesFilter: {
      noFilterAvailable: 'No advanced filter available',
      label: 'Advanced Analysis Filter',
      edit: 'Edit Advanced Filter',
      add: 'Add Advanced Filter',
      items: {
        label: 'Analysis',
        value: 'Value',
        operator: 'Operator',
      },
      filterTypes: {
        values: {
          [AdvancedAnalysisFilterType.Exists]: 'Exists',
          [AdvancedAnalysisFilterType.Equals]: '=',
          [AdvancedAnalysisFilterType.Extreme]: 'Extremwert',
          [AdvancedAnalysisFilterType.Text]: 'Text',
          [AdvancedAnalysisFilterType.Patho]: 'Pathological',
          [AdvancedAnalysisFilterType.GreaterOrEquals]: '>=',
          [AdvancedAnalysisFilterType.LessOrEquals]: '<=',
        },
      },
    },
    readStatus: 'Read Status',
  },
  filterProfile: {
    title: 'Filter Profiles',
    tooltip: 'Create new Filter Profile',
    noCustom: 'No Custom Filter available',
    edit: 'Edit filter',
    delete: {
      label: 'Delete filter',
      confirm: {
        title: 'Do you really want to delete this filter?',
        content: 'You cannot revert this action',
      },
      success: 'Deleted filter successfully',
      error: 'An error occurred',
    },
    toggleFavorite: 'Set/Unset favorite',
    create: {
      placeholder: 'Custom Filter Name',
      button: 'Save filter',
      title: 'Save Custom Filter',
      success: 'Created filter',
      error: 'Could not create filter',
    },
    update: {
      button: 'Update filter',
      title: 'Selected Filter',
      success: 'Updated filter',
      error: 'Could not update filter',
    },
  },
  status: {
    [ReportStatus.Preliminary]: 'Order',
    [ReportStatus.Final]: 'Final Report',
    [ReportStatus.Partial]: 'Partial Report',
    [ReportStatus.OrderReceived]: 'Order received',
    [ReportStatus.SpecimenReceived]: 'Specimen received',
    finalNonPatho: 'Final non pathological',
    finalPatho: 'Final pathological',
    finalBak: 'BAK final',
  },
  characteristics: {
    [ReportCharacteristic.NotPathological]: 'Not pathological',
    [ReportCharacteristic.Pathological]: 'Pathological',
    [ReportCharacteristic.Extreme]: 'Extremas',
    [ReportCharacteristic.DeltaCheck]: 'Delta Check',
  },
  readStatusType: {
    [ReadStatusType.Read]: 'Read',
    [ReadStatusType.Unread]: 'Unread',
  },
  reportType: {
    [GeneralReportFilterType.Microbiological]: 'BAK',
    [GeneralReportFilterType.Chemical]: 'KC',
    [GeneralReportFilterType.Poct]: 'POCT',
  },
  changeLog: {
    ['action_' + ReportChangeAction.NewReport]: 'New Report',
    ['action_' + ReportChangeAction.UpdatedReport]: 'Updated Report',
    ['action_' + ReportChangeAction.NewDoctor]: 'New Doctor',
    ['action_' + ReportChangeAction.NewPatient]: 'New Patient',
    ['action_' + ReportChangeAction.LockedReport]: 'Locked Report',
    ['action_' + ReportChangeAction.ImportELPHO]: 'ELPHO imported',
    ['action_' + ReportChangeAction.EmailToLabor]: 'E-mail sent to laboratory',
    ['action_' + ReportChangeAction.MarkCorrectReport]: 'Correct report marked',
    ['action_' + ReportChangeAction.ImportPDF]: 'PDF Report imported',
    ['action_' + ReportChangeAction.ReRequest]: 'Re-request',
    ['action_' + ReportChangeAction.UnlockedReport]: 'Report unlocked',
    ['action_' + ReportChangeAction.DeletedAttachment]: 'Deleted Attachment',
    ['action_' + ReportChangeAction.CreateComment]: 'Created Comment',
    ['action_' + ReportChangeAction.DeleteComment]: 'Deleted Comment',
    ['action_' + ReportChangeAction.ManualRead]: 'Set Read',
    ['action_' + ReportChangeAction.ManualUnread]: 'Set Unread',
    ['action_' + ReportChangeAction.UploadedAttachment]: 'Attachment uploaded',
    ['action_' + ReportChangeAction.WpusernameSent]: 'wpusername sent',
  },
  lanr: {
    title: 'Doctors number',
    placeholder: 'Select Doctor',
  },
  controls: {
    attachments: {
      label: 'Attachments',
      listHeader: 'Uploaded attachments',
      uploaded: 'Uploaded',
      notifications: {
        success: 'Uploaded attachment successfully',
      },
      form: {
        submit: 'Upload file',
        cancel: 'Go Back',
        description: 'Optional Description',
      },
      delete: {
        label: 'Delete Attachment',
        notifications: {
          success: 'Deleted attachment successfully',
        },
        confirm: {
          title: 'Do you really want to delete this attachment?',
          content: 'You cannot revert this action',
        },
      },
    },
    pdf: {
      label: 'Export PDF',
      export: 'Export PDF',
      original: 'Original report PDF',
      originalAdditional: 'Additional report',
      originalExclusive: 'Original Exclusive PDF',
      patient: 'Patient PDF',
      cumulative: 'Cumulative PDF',
      cumulativeAll: 'PDF-Export all values',
      notFound: 'No pdf available.',
    },
    comments: {
      label: 'Comments',
      notifications: {
        success: 'Created comment successfully',
      },
      listHeader: 'Comments',
      form: {
        submit: 'Add Comment',
        labels: {
          comment: 'Comment',
        },
      },
      delete: {
        label: 'Delete Comment',
        notifications: {
          success: 'Deleted comment successfully',
        },
        confirm: {
          title: 'Do you really want to delete this comment?',
          content: 'You cannot revert this action',
        },
      },
    },
    cumulative: {
      label: 'Cumulative View',
      mergeSameDays: 'Merge same days',
      expandSameDays: 'Expand same days',
    },
    graphic: {
      label: 'Graphic View',
      multiMode: 'Multi Select',
      weightByDate: 'Weight by date',
    },
    elpho: {
      label: 'Electrophoresis',
    },
    contactLabor: {
      label: 'Contact Laboratory',
      notifications: {
        success: 'Contacted laboratory successfully',
      },
      subject: 'Subject',
      message: 'Message',
      copyToUser: 'Send copy to myself ({email})',
    },
    unrealRerequest: {
      label: 'Rerequest',
      notifications: {},
      message: 'Message',
      copyToUser: 'Send copy to myself ({email})',
    },
    accessLog: {
      label: 'Access Log',
      unknownUser: 'Unknown User',
      types: {
        [ReportAccessAction.View]: 'View',
        [ReportAccessAction.ExportPdf]: 'PDF Export',
        [ReportAccessAction.History]: 'History',
        [ReportAccessAction.CumulativeView]: 'Cumulative View',
        [ReportAccessAction.LexicalView]: 'Lexical View',
        [ReportAccessAction.QuickAccessView]: 'Quick Access View',
        [ReportAccessAction.QuickScanView]: 'Quick Scan View',
        [ReportAccessAction.ReportPushPathological]: 'Report sent via push (Pathological)',
        [ReportAccessAction.ReportPushAll]: 'Report sent via push (all)',
        [ReportAccessAction.ReportPushExtreme]: 'Report sent via push (extreme)',
        [ReportAccessAction.ReportPushDelta]: 'Report sent via push (delta)',
        [ReportAccessAction.ReportPushCustom]: 'Report sent via push (custom)',
        [ReportAccessAction.DoctorEmailNotification]: 'Report sent via Email (doctor)',
        [ReportAccessAction.PatientEmailNotification]: 'Report sent via Email (patient',
        [ReportAccessAction.DoctorSmsNotification]: 'Report sent via SMS (doctor)',
        [ReportAccessAction.PatientSmsNotification]: 'Report sent via SMS (patient)',
        [ReportAccessAction.ReportSendFax]: 'Report sent via Fax',
        [ReportAccessAction.ReportSendEmail]: 'Report sent via Email',
        [ReportAccessAction.QuickReportPush]: 'Quick report sent via push',
      },
    },
    changeLog: {
      label: 'Change Log',
      unknownUser: 'Unknown User',
    },
    sendReport: {
      label: 'Send Report',
      notifications: {
        success: 'Sent report successfully',
      },
      sendToPatient: 'Send to patient ({email})',
      email: 'E-Mail',
      message: 'Message',
      pdfPassword: 'PDF password',
      copyToUser: 'Send copy to myself ({email})',
    },
    setRead: {
      label: 'Set Read',
    },
    setUnread: {
      label: 'Set Unread',
    },
    togglePin: {
      label: {
        pin: 'Pin Report',
        unpin: 'Unpin Report',
      },
    },
    deleteReport: {
      label: 'Delete Report',
      notifications: {
        success: 'Deleted report successfully',
      },
      confirm: {
        title: 'Do you really want to delete this report?',
        content: 'You cannot revert this action',
      },
    },
    sendReportApi: {
      label: 'Send Report API',
      notifications: {
        success: 'Report successfully sent',
      },
      confirm: {
        title: 'Do you really want to resend this report?',
        content: '',
      },
    },
    unlockReport: {
      label: 'Unlock Report',
      notifications: {
        success: 'Unlocked report successfully',
      },
      confirm: {
        title: 'Do you really want to unlock this report?',
        content: 'You cannot revert this action',
      },
    },
  },
  endless: {
    title: 'Scroll report - {NAME}',
    button: 'Show all values',
  },
  quickScan: {
    title: 'Quick Result',
    startButton: 'Show Quick Results',
    resultWithValue: 'Result: {result}',
    result: 'Your Result',
    scan: 'Scan QR Code for your results',
    orAlternatively: 'Or alternatively',
    noQr: 'I don`t have a QR-Code',
    scanButton: 'Scan QR-Code',
    quickAccess: {
      control: 'Enter Order Number',
      title: 'Enter order number and your personal data',
    },
    enterGuid: {
      control: 'Or press here to enter 10-digit code manually',
      title: 'Enter QR Code number',
      backToScan: 'Scan QR Code',
      inputError: 'This must be exactly 10-digits long',
      formDescription: 'The QR number is located directly above the QR code.',
    },
    enterEmail: {
      title: 'Report in progress',
      formLabel: 'Please enter your email here',
      warningDescription: 'Your report is still in progress. If you want to get notified as soon as it is available, please enter your email here.',
      notifications: {
        success: 'Your email has been saved. You will get notified, as soon as your results are available.',
        error: 'Something went wrong',
      },
    },
    reportLocked: {
      title: 'Please note!',
      warningDescription: 'The report is not available for download. If you have any further questions please contact your doctor or the laboratory.',
    },
    downloadPdf: 'Download PDF',
    pdfForm: {
      passwordTitle: 'Please enter the password you got from your laboratory to download the PDF',
      dataTitle: 'Please enter the your personal data to verify yourself.',
      lastName: 'Last name',
      lastNamePlaceholder: '',
      firstNamePlaceholder: '',
      firstName: 'First name',
      zip: 'Zip Code',
      password: 'Password',
      passportNumber: 'Passport number',
      idCardNumber: 'ID card number',
      birthday: 'Birthday',
    },
    notFoundWarning: {
      title: 'Report in progress',
      content: 'Please try again later.',
    },
    dataError: {
      title: 'Provided data not valid',
      content: 'Please check all fields and retry.',
    },
    pdfError: {
      title: 'PDF Download failed',
      content: 'Please try again later.',
    },
  },
  quickAccess: {
    header: 'Welcome',
    subheader: 'on the results download page of your lab.',
    description: 'Enter order number and personal data',
    privacyLabel: 'I agree with the transmission and processing of my data',
    birthday: 'Birthday ({format})',
    submit: 'Download Report as PDF',
    bannerText: 'Quick and easy access to your Corona Virus results as PDF.',
    extraNotice: '',
    extraText: '',
    infoModal: {
      title: 'How to find the order number',
      content: 'On this page, you can view your current results for your Coronavirus SARS-CoV-2 test. Please enter the 10-digit order number of the desired report and your date of birth. Start the download by clicking on "Download report". You will receive your test result within a PDF file. You can only search for reports from the past 30 days. By entering your data, you consent to the transmission and processing of your data.',
    },
    multiDownload: {
      title: 'Multiple reports available',
      description: 'Please download all pdfs to get all your results.',
    },
    useConditions: {
      label: 'Conditions',
      text: '',
    },
    error: {
      notFound: {
        title: 'We couldn´t find your report',
        content: 'Please try again later or check the data you provided.',
      },
      inProcess: {
        title: 'Your report is being processed',
        content: 'There is no data available yet. Please try again later!',
      },
      locked: {
        title: 'Please note!',
        content: 'The report is not available for download. If you have any further questions please contact your doctor or the laboratory.',
      },
      inProcessAlreadyRegistered: {
        title: 'Your report is being processed',
        content: 'You have already registered an e-mail and will get notified, as soon as the report is available.',
      },
      analysesEnabled: {
        title: 'The laboratory report contains analyses that do not allow PDF download. Please consult your responsible doctor for further informations.',
      },
      analysesDisabled: {
        title: 'The laboratory report contains analyses that do not allow PDF download. Please consult your responsible doctor for further informations.',
      },
      wrongAttempts: {
        title: 'Too many wrong attempts!',
        content: 'Please try again later.',
      },
      acceptPrivacy: {
        title: 'Please accept the privacy statement',
      },
    },
  },
};
