import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Platform } from 'interfaces/api';
import { initializeEnvStore } from 'providers/EnvProvider';
import * as Sentry from '@sentry/capacitor';
import { init as sentryReactInit } from '@sentry/react';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ErrorBoundary, SentryErrorBoundary } from 'containers';
import {
  AntProvider,
  AppUpdateProvider,
  BarcodeProvider,
  BiometricsProvider,
  ConfigProvider,
  IntlProvider,
  MaintenanceProvider,
  NetworkCheckProvider,
  PushNotificationProvider,
  SSEProvider,
} from 'providers';
import { AuthProvider } from 'modules/auth/providers/AuthProvider';
import { App } from 'containers/App';
import { Compose } from 'containers/Compose';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { NotificationsProvider } from 'modules/notifications/providers/Notifications.tsx';
import { StoreProvider } from 'modules/store/store/StoreProvider';

dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

(async () => {

  const env = await initializeEnvStore();

  if (env.isProd && env.DISABLE_SENTRY !== '1') {
    Sentry.init({
      dsn: 'https://57f503e2552f405f887928e7c494eca0@sentry.labuniq.com/2',

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      environment: import.meta.env.MODE,
      release: 'onlinebefunde@' + APP_VERSION,
    }, sentryReactInit);

    Sentry.setContext('config', env);
    Sentry.setTag('target', env.TARGET);
  }

  const render = async () => {

    const container = document.getElementById('root');
    const root = ReactDOM.createRoot(container);

    root.render(
      <SentryErrorBoundary>
        <Compose
          components={[
            typeof (window as any).cordova !== 'undefined' ? HashRouter : BrowserRouter,
            IntlProvider,
            AntProvider,
            AppUpdateProvider,
            AuthProvider,
            SSEProvider,
            NetworkCheckProvider,
            MaintenanceProvider,
            BiometricsProvider,
            ErrorBoundary,
            ConfigProvider,
            NotificationsProvider,
            PushNotificationProvider,
            BarcodeProvider,
            StoreProvider,
          ]}
        >
          <App/>
        </Compose>
      </SentryErrorBoundary>,
    );
  };

  if (env.platform === Platform.WEB) {
    return render();
  } else {
    await render();
    document.getElementById('root').classList.add('is-cordova');
  }

})();
