import React from 'react';
import { BadgeProps, IconProps, Popover } from 'components';
import { Message } from 'interfaces';
import { Control, ControlProps } from './Control';
import './ControlGroup.less';
import { setChildrenProps } from 'utils/helpers';

export type ControlGroupProps = {
  icon: IconProps;
  label: Message;
  badge?: BadgeProps;
  children: React.ReactElement<ControlProps>[];
  className?: string;
};

export const ControlGroup = (props: ControlGroupProps) => {

  const { icon, label, badge } = props;

  const children = React.Children.toArray(props.children).filter(c => !!c);

  if (children.length === 0) {
    return null;
  }

  if (children.length === 1) {
    return <>{setChildrenProps(children, () => ({ icon }))}</>;
  }

  const control = { icon, label, badge };

  const content = (
    <ul>
      {children.map((child, index) => <li key={index}>{child}</li>)}
    </ul>
  );

  return (
    <Popover content={content} placement={'bottomLeft'} classNames={{ root: 'control-group' }}>
      <span>
        <Control {...control} tooltip={{ open: false }}/>
      </span>
    </Popover>
  );

};
