import React, { useCallback, useEffect } from 'react';
import { useOrderRulesContext } from './OrderRulesProvider';
import messages from 'messages';
import { useTranslate } from 'providers';
import { flattenDeep } from 'lodash';
import { usePrevious } from 'react-use';
import { OrderRulesOrderReasonActionOptionsDto } from 'interfaces/api';
import { App } from 'antd';

export const useOrderReason = (showWarning?: boolean) => {

  const translate = useTranslate();
  const { message } = App.useApp();

  const { getErrorState } = useOrderRulesContext();

  const {
    hasErrors: hasOrderReasonError,
    hasBasketErrors: hasOrderReasonBasketError,
    basketErrors: orderReasonBasketError,
    errors: orderReasonError,
  } = getErrorState<OrderRulesOrderReasonActionOptionsDto>('OrderRulesOrderReasonError');

  const previousHasOrderReasonError = usePrevious(hasOrderReasonBasketError);

  const formatOrderReasonError = useCallback((error: any) => {
    const shortNameAction = error.shortName;
    const shortNameCondition = error.conditions.map((c: any) => c.options.shortName).join(', ');

    return translate(messages.orders.wrongOrderReason, { shortNameAction, shortNameCondition });
  }, [translate]);

  useEffect(() => {
    if (hasOrderReasonBasketError && !previousHasOrderReasonError && showWarning) {
      flattenDeep(orderReasonBasketError).forEach(error => message.warning((
        <span dangerouslySetInnerHTML={{ __html: formatOrderReasonError(error) }}/>
      ), 3));
    }
  }, [hasOrderReasonBasketError, previousHasOrderReasonError, formatOrderReasonError]);

  return {
    orderReasonError,
    hasOrderReasonError,
    formatOrderReasonError,
  };

};
