import React from 'react';
import {
  useAnamnesisContext,
  useInvalidFields,
  useMandatoryDiagnoses,
  useMandatoryDialysis,
  useMandatoryWorkstation,
  useMissingFields,
  useMissingFreetext,
  useOrderRulesContext,
  useShowHints,
} from 'modules/orders/providers';
import { Modal } from 'components';
import * as Actions from './Actions';
import { ValidationError } from 'modules/orders/components/OrderRuleModal/ValidationError';

export const OrderRuleModal = () => {

  const { showHints } = useShowHints();
  const { missingFields } = useMissingFields();
  const { missingFreetext } = useMissingFreetext();
  const { diagnosisErrors } = useMandatoryDiagnoses();
  const { anamnesisErrors } = useAnamnesisContext();
  const { hasMissingWorkstationError } = useMandatoryWorkstation();
  const { hasMissingDialysisError } = useMandatoryDialysis();
  const { invalidFields } = useInvalidFields();

  const { errors } = useOrderRulesContext();

  return (
    <Modal
      open={!!errors?.length}
      footer={null}
      closable={false}
      children={() => {
        if (missingFields) {
          return <Actions.MissingFields/>;
        } else if (anamnesisErrors) {
          return <Actions.Anamnesis/>;
        } else if (invalidFields) {
          return <Actions.InvalidFields/>;
        } else if (showHints) {
          return <Actions.Hints/>;
        } else if (missingFreetext) {
          return <Actions.Freetext/>;
        } else if (diagnosisErrors) {
          return <Actions.Diagnoses/>;
        } else if (hasMissingWorkstationError) {
          return <Actions.Workstation/>;
        } else if (hasMissingDialysisError) {
          return <Actions.BeforeAfterDialysis/>;
        } else if (errors?.length) {
          return <ValidationError/>;
        }
        return null;
      }}
    />
  );

};
