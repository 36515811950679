import React, { useCallback, useEffect } from 'react';
import { useOrderRulesContext } from './OrderRulesProvider';
import messages from 'messages';
import { useTranslate } from 'providers';
import { flattenDeep } from 'lodash';
import { usePrevious } from 'react-use';
import { OrderRulesMandatoryRequirementActionOptionsDto } from 'interfaces/api';
import { App } from 'antd';

export const useMandatoryRequirements = (showWarning?: boolean) => {

  const translate = useTranslate();
  const { message } = App.useApp();

  const { getErrorState } = useOrderRulesContext();

  const {
    hasErrors: hasMandatoryRequirementError,
    hasBasketErrors: hasMandatoryRequirementBasketError,
    basketErrors: mandatoryRequirementBasketError,
    errors: mandatoryRequirements,
  } = getErrorState<OrderRulesMandatoryRequirementActionOptionsDto>('OrderRulesMandatoryRequirementsError');

  const previousHasMandatoryRequirementError = usePrevious(hasMandatoryRequirementBasketError);

  const formatMandatoryRequirementError = useCallback((error: any) => {
    const shortNameAction = error.shortName;
    const shortNameCondition = error.conditions.map((c: any) => c.options.shortName).join(', ');

    return translate(messages.orders.mandatoryRequirements, { shortNameAction, shortNameCondition });
  }, [translate]);

  useEffect(() => {
    if (hasMandatoryRequirementBasketError && !previousHasMandatoryRequirementError && showWarning) {
      flattenDeep(mandatoryRequirementBasketError).forEach(error => message.warning((
        <span dangerouslySetInnerHTML={{ __html: formatMandatoryRequirementError(error) }}/>
      ), 3));
    }
  }, [hasMandatoryRequirementBasketError, previousHasMandatoryRequirementError, formatMandatoryRequirementError]);

  return {
    mandatoryRequirements,
    hasMandatoryRequirementError,
    formatMandatoryRequirementError,
  };

};
