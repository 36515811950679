import { useCallback } from 'react';
import { AggregatedProfileProperties, AggregatedRequirementFlags, OrderChangeAction, OrderWizardRequirement } from 'interfaces/api';
import { filter, includes } from 'lodash';
import { useBasketContext } from 'modules/orders/containers/OrderWizard/providers/BasketProvider/BasketProvider';
import { useAppendLog, useSetOrders } from 'modules/orders/providers';
import { isProfile } from 'modules/orders/containers/OrderWizard/utils';
import { useUpsertBasket } from 'modules/orders/containers/OrderWizard/providers/BasketProvider/useUpsertBasket';

export const useToggleBasket = () => {

  const { setAllOrSelectedOrders, setOrdersMapped } = useSetOrders();

  const {
    poolRequirementsAllInBasket,
    poolRequirementsPartialInBasket,
    profileRequirementsAllInBasket,
    profileRequirementsPartialInBasket,
    superRequirementAllInBasket,
    superRequirementPartialInBasket,
  } = useBasketContext();

  const upsertBasket = useUpsertBasket();
  const appendLog = useAppendLog();

  // remove requirement from basket
  const removeRequirement = useCallback((requirement: OrderWizardRequirement) => {
    setAllOrSelectedOrders(order => ({ ...order, requirements: order.requirements.filter(r => r.id !== requirement.id) }));
    appendLog(OrderChangeAction.RemoveRequirement, requirement);
  }, [appendLog, setAllOrSelectedOrders]);

  // remove profile from basket
  const removeProfileFromBasket = useCallback((requirement: AggregatedProfileProperties) => {
    appendLog(OrderChangeAction.RemoveProfileRequirement, requirement);
    setOrdersMapped((order) => {
      const freeText = order.freeText ? filter(order.freeText.split(';'), f => f !== requirement.freeText).join(';') : undefined;
      const requirements = order.requirements.filter(r => r.profileId !== requirement.profileId || r.profileType !== requirement.profileType);
      return { ...order, freeText, requirements };
    });
  }, [appendLog, setOrdersMapped]);

  // remove super requirement from basket
  const removeSuperRequirementFromBasket = useCallback((requirement: OrderWizardRequirement) => {
    setOrdersMapped(order => ({
      ...order,
      requirements: order.requirements.filter(r => !includes(requirement.subRequirements, r.id) && r.id !== requirement.id),
    }));
    appendLog(OrderChangeAction.RemoveRequirement, requirement);
  }, [appendLog, setOrdersMapped]);

  /**
   * toggle profile
   */
  const toggleProfile = useCallback((requirement: AggregatedProfileProperties, fromBasket?: boolean) => {
    if (profileRequirementsAllInBasket(requirement) || (profileRequirementsPartialInBasket(requirement) && fromBasket)) {
      removeProfileFromBasket(requirement);
    } else {
      upsertBasket(requirement);
    }
  }, [profileRequirementsAllInBasket, profileRequirementsPartialInBasket, removeProfileFromBasket, upsertBasket]);

  /**
   * toggle super requirement
   */
  const toggleSuperRequirement = useCallback((requirement: OrderWizardRequirement, fromBasket?: boolean) => {
    if (superRequirementAllInBasket(requirement) || (superRequirementPartialInBasket(requirement) && fromBasket)) {
      removeSuperRequirementFromBasket(requirement);
    } else {
      upsertBasket(requirement);
    }
  }, [superRequirementAllInBasket, superRequirementPartialInBasket, removeSuperRequirementFromBasket, upsertBasket]);

  /**
   * toggle requirement
   */
  const toggleRequirement = useCallback((requirement: OrderWizardRequirement, fromBasket?: boolean) => {
    if (poolRequirementsAllInBasket(requirement) || (poolRequirementsPartialInBasket(requirement) && fromBasket)) {
      removeRequirement(requirement);
    } else {
      upsertBasket(requirement);
    }
  }, [poolRequirementsAllInBasket, poolRequirementsPartialInBasket, removeRequirement, upsertBasket]);

  /**
   * main toggle basket function
   */
  return useCallback((requirement: OrderWizardRequirement | AggregatedProfileProperties, fromBasket?: boolean) => {
    if (isProfile(requirement)) {
      toggleProfile(requirement, fromBasket);
    } else if (requirement.flags?.includes(AggregatedRequirementFlags.IsSuperRequirement)) {
      toggleSuperRequirement(requirement, fromBasket);
    } else {
      toggleRequirement(requirement, fromBasket);
    }
  }, [toggleProfile, toggleSuperRequirement, toggleRequirement]);

};
